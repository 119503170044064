import { request, success, failure, IMMUNIZATION_DASHBOARD } from "./utilities";
import {
  getImzStatsApi,
  getCompletedImmunizationApi,
  getIncompletedImmunizationApi,
  getMissedImmunizationApi,
  getChildImmunizationApi,
  getPatientVaccinationApi,
  getImmunizationChartApi,
  exportMissedImmunizationExcelApi,
  exportChildImmunizationsExcelApi,
  exportStatsExcelApi,
  exportPatientVaccinationExcelApi,
  getRefusedAnotherFacilityImmunizationsApi,
  getRefusedAnotherFacilityImmunizationsGraphApi,
  getLookupsApi,
  getDeleteImmunizationRequestsApi,
  approveOrDenyImmunizationDeleteRequestApi,
} from "../api";
import { downloadBase64File } from "../../app/constant";

export function getImzStateAction(filters) {
  return (dispatch) => {
    dispatch(request(IMMUNIZATION_DASHBOARD.GET_IMZ_STATS_REQUEST));
    getImzStatsApi(filters).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              IMMUNIZATION_DASHBOARD.GET_IMZ_STATS_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              IMMUNIZATION_DASHBOARD.GET_IMZ_STATS_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(IMMUNIZATION_DASHBOARD.GET_IMZ_STATS_FAILURE, error.message)
        );
      }
    );
  };
}

export function getImmunizationChartAction(filters) {
  return (dispatch) => {
    dispatch(request(IMMUNIZATION_DASHBOARD.GET_IMMUNIZATION_CHART_REQUEST));
    getImmunizationChartApi(filters).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              IMMUNIZATION_DASHBOARD.GET_IMMUNIZATION_CHART_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              IMMUNIZATION_DASHBOARD.GET_IMMUNIZATION_CHART_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            IMMUNIZATION_DASHBOARD.GET_IMMUNIZATION_CHART_FAILURE,
            error.message
          )
        );
      }
    );
  };
}

export function getCompletedImmunizationAction(filters) {
  return (dispatch) => {
    dispatch(
      request(IMMUNIZATION_DASHBOARD.GET_COMPLETED_IMMUNIZATION_REQUEST)
    );
    getCompletedImmunizationApi(filters).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              IMMUNIZATION_DASHBOARD.GET_COMPLETED_IMMUNIZATION_SUCCESS,
              response.data.data,
              { count: response.data.totalRecords }
            )
          );
        } else {
          dispatch(
            failure(
              IMMUNIZATION_DASHBOARD.GET_COMPLETED_IMMUNIZATION_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            IMMUNIZATION_DASHBOARD.GET_COMPLETED_IMMUNIZATION_FAILURE,
            error.message
          )
        );
      }
    );
  };
}

export function getIncompletedImmunizationAction(filters) {
  return (dispatch) => {
    dispatch(
      request(IMMUNIZATION_DASHBOARD.GET_INCOMPLETED_IMMUNIZATION_REQUEST)
    );
    getIncompletedImmunizationApi(filters).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              IMMUNIZATION_DASHBOARD.GET_INCOMPLETED_IMMUNIZATION_SUCCESS,
              response.data.data,
              { count: response.data.totalRecords }
            )
          );
        } else {
          dispatch(
            failure(
              IMMUNIZATION_DASHBOARD.GET_INCOMPLETED_IMMUNIZATION_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            IMMUNIZATION_DASHBOARD.GET_INCOMPLETED_IMMUNIZATION_FAILURE,
            error.message
          )
        );
      }
    );
  };
}
export function getRefusedAnotherFacilityImmunizationsAction(filters) {
  return (dispatch) => {
    dispatch(
      request(IMMUNIZATION_DASHBOARD.GET_REFUSED_ANOTHER_REQUEST)
    );
    getRefusedAnotherFacilityImmunizationsApi(filters).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              IMMUNIZATION_DASHBOARD.GET_REFUSED_ANOTHER_SUCCESS,
              response.data.data,
              { count: response.data.totalRecords }
            )
          );
        } else {
          dispatch(
            failure(
              IMMUNIZATION_DASHBOARD.GET_REFUSED_ANOTHER_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            IMMUNIZATION_DASHBOARD.GET_REFUSED_ANOTHER_FAILURE,
            error.message
          )
        );
      }
    );
  };
}
export function getRefusedAnotherFacilityImmunizationsGraphAction(filters) {
  return (dispatch) => {
    dispatch(
      request(IMMUNIZATION_DASHBOARD.GET_REFUSED_ANOTHER_GRAPH_REQUEST)
    );
    getRefusedAnotherFacilityImmunizationsGraphApi(filters).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              IMMUNIZATION_DASHBOARD.GET_REFUSED_ANOTHER_GRAPH_SUCCESS,
              response.data.data,
              { count: response.data.totalRecords }
            )
          );
        } else {
          dispatch(
            failure(
              IMMUNIZATION_DASHBOARD.GET_REFUSED_ANOTHER_GRAPH_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            IMMUNIZATION_DASHBOARD.GET_REFUSED_ANOTHER_GRAPH_FAILURE,
            error.message
          )
        );
      }
    );
  };
}
export function getLookupsAction(filters) {
  return (dispatch) => {
    dispatch(
      request(IMMUNIZATION_DASHBOARD.GET_LOOKUP_FACILITY_REQUEST)
    );
    getLookupsApi(filters).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              IMMUNIZATION_DASHBOARD.GET_LOOKUP_FACILITY_SUCCESS,
              response.data.data,
              { count: response.data.totalRecords }
            )
          );
        } else {
          dispatch(
            failure(
              IMMUNIZATION_DASHBOARD.GET_LOOKUP_FACILITY_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            IMMUNIZATION_DASHBOARD.GET_LOOKUP_FACILITY_FAILURE,
            error.message
          )
        );
      }
    );
  };
}

export function getMissedImmunizationAction(filters) {
  return (dispatch) => {
    dispatch(request(IMMUNIZATION_DASHBOARD.GET_MISSED_IMMUNIZATION_REQUEST));
    getMissedImmunizationApi(filters).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              IMMUNIZATION_DASHBOARD.GET_MISSED_IMMUNIZATION_SUCCESS,
              response.data.data,
              { count: response.data.totalRecords }
            )
          );
        } else {
          dispatch(
            failure(
              IMMUNIZATION_DASHBOARD.GET_MISSED_IMMUNIZATION_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            IMMUNIZATION_DASHBOARD.GET_MISSED_IMMUNIZATION_FAILURE,
            error.message
          )
        );
      }
    );
  };
}

export function getChildImmunizationAction(filters) {
  return (dispatch) => {
    dispatch(request(IMMUNIZATION_DASHBOARD.GET_CHILD_IMMUNIZATION_REQUEST));
    getChildImmunizationApi(filters).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              IMMUNIZATION_DASHBOARD.GET_CHILD_IMMUNIZATION_SUCCESS,
              response.data.data ? response.data.data : [],
              { count: response.data.totalRecords }
            )
          );
        } else {
          dispatch(
            failure(
              IMMUNIZATION_DASHBOARD.GET_CHILD_IMMUNIZATION_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            IMMUNIZATION_DASHBOARD.GET_CHILD_IMMUNIZATION_FAILURE,
            error.message
          )
        );
      }
    );
  };
}
export function getDeleteImmunizationRequestsAction(filters) {
  return (dispatch) => {
    dispatch(request(IMMUNIZATION_DASHBOARD.GET_DELETE_IMMUNIZATION_REQUESTS_REQUEST));
    getDeleteImmunizationRequestsApi(filters).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              IMMUNIZATION_DASHBOARD.GET_DELETE_IMMUNIZATION_REQUESTS_SUCCESS,
              response.data.data ? response.data.data : [],
              { count: response.data.totalRecords }
            )
          );
        } else {
          dispatch(
            failure(
              IMMUNIZATION_DASHBOARD.GET_DELETE_IMMUNIZATION_REQUESTS_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            IMMUNIZATION_DASHBOARD.GET_DELETE_IMMUNIZATION_REQUESTS_FAILURE,
            error.message
          )
        );
      }
    );
  };
}
export function approveOrDenyImmunizationDeleteRequestAction(filters, onHandleOnSuccess) {
  return (dispatch) => {
    dispatch(request(IMMUNIZATION_DASHBOARD.APPROVE_OR_DENY_IMMUNIZATION_REQUEST));
    approveOrDenyImmunizationDeleteRequestApi(filters).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              IMMUNIZATION_DASHBOARD.APPROVE_OR_DENY_IMMUNIZATION_SUCCESS,
              response.data.data ? response.data.data : [],
              { count: response.data.totalRecords }
            )
          );
          if (onHandleOnSuccess) {
            onHandleOnSuccess()
          }
        } else {
          dispatch(
            failure(
              IMMUNIZATION_DASHBOARD.APPROVE_OR_DENY_IMMUNIZATION_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            IMMUNIZATION_DASHBOARD.APPROVE_OR_DENY_IMMUNIZATION_FAILURE,
            error.message
          )
        );
      }
    );
  };
}

export function getPatientVaccinationAction(filters) {
  return (dispatch) => {
    dispatch(request(IMMUNIZATION_DASHBOARD.GET_PATIENT_VACCINATION_REQUEST));
    getPatientVaccinationApi(filters).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              IMMUNIZATION_DASHBOARD.GET_PATIENT_VACCINATION_SUCCESS,
              response.data.data,
              { count: response.data.totalRecords }
            )
          );
        } else {
          dispatch(
            failure(
              IMMUNIZATION_DASHBOARD.GET_PATIENT_VACCINATION_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            IMMUNIZATION_DASHBOARD.GET_PATIENT_VACCINATION_FAILURE,
            error.message
          )
        );
      }
    );
  };
}

export function exportStatsExcelAction(filters, fileName, onSuccessCallback) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(request(IMMUNIZATION_DASHBOARD.EXPORT_STATS_EXCEL_REQUEST));
    exportStatsExcelApi(filters)
      .then(
        (response) => {
          if (response.data !== undefined) {
            onSuccess = true;

            dispatch(
              success(
                IMMUNIZATION_DASHBOARD.EXPORT_STATS_EXCEL_SUCCESS,
                response.data
              )
            );

            downloadBase64File(response.data, fileName);
          } else {
            onFailure = true;

            dispatch(
              failure(
                IMMUNIZATION_DASHBOARD.EXPORT_STATS_EXCEL_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(
              IMMUNIZATION_DASHBOARD.EXPORT_STATS_EXCEL_FAILURE,
              error.message
            )
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}

export function exportMissedImmunizationAction(
  filters,
  fileName,
  onSuccessCallback
) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(
      request(IMMUNIZATION_DASHBOARD.EXPORT_MISSED_IMMUNIZATION_REQUEST)
    );
    exportMissedImmunizationExcelApi(filters)
      .then(
        (response) => {
          if (response.data !== undefined) {
            onSuccess = true;

            dispatch(
              success(
                IMMUNIZATION_DASHBOARD.EXPORT_MISSED_IMMUNIZATION_SUCCESS,
                response.data.data
              )
            );

            downloadBase64File(response.data, fileName);
          } else {
            onFailure = true;

            dispatch(
              failure(
                IMMUNIZATION_DASHBOARD.EXPORT_MISSED_IMMUNIZATION_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(
              IMMUNIZATION_DASHBOARD.GET_PATIENT_VACCINATION_FAILURE,
              error.message
            )
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}

export function exportChildImmunizationAction(
  filters,
  fileName,
  onSuccessCallback
) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(request(IMMUNIZATION_DASHBOARD.EXPORT_CHILD_IMMUNIZATION_REQUEST));
    exportChildImmunizationsExcelApi(filters)
      .then(
        (response) => {
          if (response.data !== undefined) {
            onSuccess = true;
            dispatch(
              success(
                IMMUNIZATION_DASHBOARD.EXPORT_CHILD_IMMUNIZATION_SUCCESS,
                response.data.data
              )
            );

            downloadBase64File(response.data, fileName);
          } else {
            onFailure = true;

            dispatch(
              failure(
                IMMUNIZATION_DASHBOARD.EXPORT_CHILD_IMMUNIZATION_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(
              IMMUNIZATION_DASHBOARD.EXPORT_CHILD_IMMUNIZATION_FAILURE,
              error.message
            )
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}

export function exportPatientVaccinationAction(
  filters,
  fileName,
  onSuccessCallback
) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;
    dispatch(request(IMMUNIZATION_DASHBOARD.EXPORT_PATIENT_VACCINATION_REQUEST));
    exportPatientVaccinationExcelApi(filters)
      .then(
        (response) => {
          if (response.data !== undefined) {
            onSuccess = true;

            dispatch(
              success(
                IMMUNIZATION_DASHBOARD.EXPORT_PATIENT_VACCINATION_SUCCESS,
                response.data.data
              )
            );

            downloadBase64File(response.data, fileName);
          } else {
            onFailure = true;

            dispatch(
              failure(
                IMMUNIZATION_DASHBOARD.EXPORT_PATIENT_VACCINATION_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(
              IMMUNIZATION_DASHBOARD.EXPORT_CHILD_IMMUNIZATION_FAILURE,
              error.message
            )
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}
