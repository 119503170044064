/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";
import Select from "react-select";
import { PATH } from "../../../../../app/config";
import { ROLE_POLICES, dashboardOption } from "../../../../../app/constant";

import {
  FaClinicMedical,
  FaUserMd,
  FaVideo,
  FaListUl,
  FaHandHoldingHeart,
  FaBaby,
  FaHospitalUser,
  FaTachometerAlt,
  FaUserShield,
} from "react-icons/fa";
import { FcDataConfiguration } from "react-icons/fc";
import { useDispatch, useSelector } from "react-redux";
import { getAllCountAction } from "../../../../../redux/actions";
import { HeadsetMicOutlined } from "@material-ui/icons";
import { KEY_PREFIX } from "redux-persist";

export function AsideMenuList({ layoutProps }) {
  const [dashboard, setDashboard] = useState({});
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const counts = useSelector((state) => state.mainDashboard);
  let policesData = useSelector((state) => state?.clinicsReducer);
  const { user } = useSelector((state) => state.auth);
  useEffect(() => {
    if (!counts.allCountRecords.length > 0) {
      dispatch(getAllCountAction());
    }
    //eslint-disable-next-line
  }, []);


  const getCount = (id) => {
    if (id == 9)
      return counts.allCountRecords.totalAdmins || 0
    else if (id == 10)
      return counts.allCountRecords.totalDoctors || 0
    else if (id == 11)
      return counts.allCountRecords.totalCaregivers || 0
    else if (id == 12)
      return counts.allCountRecords.totalPatients || 0
    else if (id == 8)
      return counts.allCountRecords.totalClinics || 0
    else if (id == 13)
      return counts.allCountRecords.totalChilds || 0
    else return -1
  }
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
      "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  const handleOnChange = ({ value }) => {
    if (value) {
      if (value === 1) {
        return history.push(PATH.MAIN_DASHBOARD);
      }
      if (value === 2) {
        return history.push(PATH.IMMUNIZATION_DASHBOARD);
      }
      if (value === 3) {
        return history.push(PATH.ANC_DASHBOARD);
      }
      if (value === 4) {
        return history.push(PATH.OPD_FIVE_REGISTER_DASHBOARD);
      }
      if (value === 5) {
        return history.push(PATH.OPD_UNDER_FIVE_REGISTER_DASHBOARD);
      }
      if (value === 6) {
        return history.push(PATH.PRIMARY_HEALTH_UNIT_DASHBOARD);
      }
      if (value === 7) {
        return history.push(PATH.HOSPITAL_INPATIENT_REGISTER_DASHBOARD);
      }
    }
  };



  const handleDashboard = (current) => {

    let option = [...dashboardOption];

    if (current === PATH.IMMUNIZATION_DASHBOARD) {
      return setDashboard(option[1]);
    }
    if (current === PATH.ANC_DASHBOARD) {
      return setDashboard(option[2]);
    }

    return setDashboard(option[0]);
  };

  useEffect(() => {
    handleDashboard(location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // let combineData=ROLE_POLICES.filter((item)=>policesData?.getSidebarPolicesByRoleIdData?.[0]?.policies.some(
  //   (subItem) => subItem.value === item.value
  // ))
  const combinedData = ROLE_POLICES.filter((item) =>
    policesData?.getSidebarPolicesByRoleIdData?.[0]?.policies.some(
      (subItem) => subItem.value === item.value
    )
  );
  const SidebarItems = () => {
    return (
      <>
        {combinedData?.reverse()?.map((item) => {
          return (
            <>
              <li
                className={`menu-item ${getMenuItemActive(
                  item.path,
                  false
                )} ${getMenuItemActive(item.path, false)} ${getMenuItemActive(
                  item.path,
                  false
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to={item.path}>
                  <span className="svg-icon menu-icon">
                    {item.icon ? item.icon : "N/A"}
                  </span>
                  <span className="menu-text">{item.name}</span>
                  {
                    getCount(item.id) > 0 && (<span className="menu-arrow">
                      {getCount(item.id)}
                    </span>
                    )

                  }
                </NavLink>
              </li>
            </>
          );
        })}
      </>
    );
  };

  console.log("combinedData", combinedData);
  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}
        onClick={() => dispatch({ type: "RESET_LOCATIONS" })}>
        <SidebarItems />
      </ul>
    </>
  );

  // return (
  //   <>
  //     {/* begin::Menu Nav */}
  //     <ul
  //       className={`menu-nav ${layoutProps.ulClasses}`}
  //       onClick={() => dispatch({ type: "RESET_LOCATIONS" })}
  //     >
  //       {user.data.roles[0] !== "DHISAdmin" && (
  //         <>
  //           {/* {layoutProps.viewUserDisplay && ( */}
  //           {(adminsRights() || moderateAdminRights()) && (
  //             <>
  //               <li
  //                 className={`menu-item d-md-block d-lg-none ${getMenuItemActive(
  //                   PATH.MAIN_DASHBOARD,
  //                   false
  //                 )} ${getMenuItemActive(
  //                   PATH.ANC_DASHBOARD,
  //                   false
  //                 )} ${getMenuItemActive(PATH.IMMUNIZATION_DASHBOARD, false)}`}
  //                 aria-haspopup="true"
  //               >
  //                 {(superAdminRights() || moderateAdminRights()) && (
  //                   <Select
  //                     options={dashboardOption}
  //                     className="w230 mx-auto mb-5"
  //                     onChange={(value) => handleOnChange(value)}
  //                     value={dashboard}
  //                   />
  //                 )}

  //                 {onlyAdminsRights() && (
  //                   <Select
  //                     options={[
  //                       { value: 1, label: "Global Dashboard" },
  //                       { value: 2, label: "Immunization" },
  //                     ]}
  //                     className="w230 mx-auto mb-5"
  //                     onChange={(value) => handleOnChange(value)}
  //                     value={dashboard}
  //                   />
  //                 )}
  //               </li>
  //               {/* )} */}
  //               {/*begin::1 Level*/}
  //               <li
  //                 className={`menu-item ${getMenuItemActive(
  //                   PATH.MAIN_DASHBOARD,
  //                   false
  //                 )} ${getMenuItemActive(
  //                   PATH.ANC_DASHBOARD,
  //                   false
  //                 )} ${getMenuItemActive(PATH.IMMUNIZATION_DASHBOARD, false)}`}
  //                 aria-haspopup="true"
  //               >
  //                 <NavLink className="menu-link" to={PATH.MAIN_DASHBOARD}>
  //                   <span className="svg-icon menu-icon">
  //                     <FaTachometerAlt size="18" className="h-auto w-auto" />
  //                   </span>
  //                   <span className="menu-text">Dashboard</span>
  //                 </NavLink>
  //               </li>
  //             </>
  //           )}

  //           {hasClinicsAccess() && (
  //             <>
  //               <li
  //                 className={`menu-item ${getMenuItemActive(
  //                   PATH.CLINIC_LIST,
  //                   false
  //                 )}`}
  //                 aria-haspopup="true"
  //               >
  //                 <NavLink className="menu-link" to={PATH.CLINIC_LIST}>
  //                   <span className="svg-icon menu-icon">
  //                     <FaClinicMedical size="18" className="w-auto h-auto" />
  //                   </span>
  //                   <span className="menu-text">Clinic List</span>
  //                   <span className="menu-arrow">
  //                     {counts.allCountRecords.totalClinics || 0}
  //                   </span>
  //                 </NavLink>
  //               </li>
  //               {/*begin::1 Level*/}
  //               <li
  //                 className={`menu-item ${getMenuItemActive(
  //                   PATH.ADMIN_LIST,
  //                   false
  //                 )}`}
  //                 aria-haspopup="true"
  //               >
  //                 <NavLink className="menu-link" to={PATH.ADMIN_LIST}>
  //                   <span className="svg-icon menu-icon">
  //                     <FaUserShield size="18" className="w-auto h-auto" />
  //                   </span>
  //                   <span className="menu-text">Admin List</span>
  //                   <span className="menu-arrow">
  //                     {counts.allCountRecords.totalAdmins || 0}
  //                   </span>
  //                 </NavLink>
  //               </li>

  //               {/*end::1 Level*/}
  //               {/*begin::1 Level*/}

  //               {/*end::1 Level*/}
  //             </>
  //           )}
  //           {/*begin::1 Level*/}
  //           {hasDoctorsAccess() && (
  //             <>
  //               <li
  //                 className={`menu-item ${getMenuItemActive(
  //                   PATH.DOCTOR_LIST,
  //                   false
  //                 )}`}
  //                 aria-haspopup="true"
  //               >
  //                 <NavLink className="menu-link" to={PATH.DOCTOR_LIST}>
  //                   <span className="svg-icon menu-icon">
  //                     <FaUserMd size="18" className="h-auto w-auto" />
  //                   </span>
  //                   <span className="menu-text">Doctor List</span>
  //                   <span className="menu-arrow">
  //                     {counts.allCountRecords.totalDoctors || 0}
  //                   </span>
  //                 </NavLink>
  //               </li>
  //               {/*end::1 Level*/}

  //               {/*begin::1 Level*/}
  //               <li
  //                 className={`menu-item ${getMenuItemActive(
  //                   PATH.CARE_GIVER_LIST,
  //                   false
  //                 )}`}
  //                 aria-haspopup="true"
  //               >
  //                 <NavLink className="menu-link" to={PATH.CARE_GIVER_LIST}>
  //                   <span className="svg-icon menu-icon">
  //                     <FaHandHoldingHeart size="18" className="h-auto w-auto" />
  //                   </span>
  //                   <span className="menu-text">Caregiver List</span>
  //                   <span className="menu-arrow">
  //                     {counts.allCountRecords.totalCaregivers || 0}
  //                   </span>
  //                 </NavLink>
  //               </li>
  //               <li
  //                 className={`menu-item ${getMenuItemActive(
  //                   PATH.PATIENT_LIST,
  //                   false
  //                 )}`}
  //                 aria-haspopup="true"
  //               >
  //                 <NavLink className="menu-link" to={PATH.PATIENT_LIST}>
  //                   <span className="svg-icon menu-icon">
  //                     <FaHospitalUser size="18" className="h-auto w-auto" />
  //                   </span>
  //                   <span className="menu-text">Patients List</span>
  //                   <span className="menu-arrow">
  //                     {counts.allCountRecords.totalPatients || 0}
  //                   </span>
  //                 </NavLink>
  //               </li>
  //               <li
  //                 className={`menu-item ${getMenuItemActive(
  //                   PATH.CHILD_LIST,
  //                   false
  //                 )}`}
  //                 aria-haspopup="true"
  //               >
  //                 <NavLink className="menu-link" to={PATH.CHILD_LIST}>
  //                   <span className="svg-icon menu-icon">
  //                     <FaBaby size="18" className="h-auto w-auto" />
  //                   </span>
  //                   <span className="menu-text">Child List</span>
  //                   <span className="menu-arrow">
  //                     {counts.allCountRecords.totalChilds || 0}
  //                   </span>
  //                 </NavLink>
  //               </li>
  //               <li
  //                 className={`menu-item ${getMenuItemActive(
  //                   PATH.LEARNING_CENTRE,
  //                   false
  //                 )}`}
  //                 aria-haspopup="true"
  //               >
  //                 <NavLink className="menu-link" to={PATH.LEARNING_CENTRE}>
  //                   <span className="svg-icon menu-icon">
  //                     <FaVideo size="18" className="w-auto h-auto" />
  //                   </span>
  //                   <span className="menu-text">Video Learning Centre</span>
  //                 </NavLink>
  //               </li>
  //               <li
  //                 className={`menu-item ${getMenuItemActive(
  //                   PATH.LOGGING_ACTIVITY,
  //                   false
  //                 )}`}
  //                 aria-haspopup="true"
  //               >
  //                 <NavLink className="menu-link" to={PATH.LOGGING_ACTIVITY}>
  //                   <span className="svg-icon menu-icon">
  //                     <FaListUl size="18" className="w-auto h-auto" />
  //                   </span>
  //                   <span className="menu-text">Logging Activity</span>
  //                 </NavLink>
  //               </li>
  //               {/*end::1 Level*/}
  //             </>
  //           )}
  //           {/*begin::1 Level*/}

  //           {/*end::1 Level*/}

  //           {doctorRights() && (
  //             <li
  //               className={`menu-item ${getMenuItemActive(
  //                 PATH.SCHEDULE,
  //                 false
  //               )}`}
  //               aria-haspopup="true"
  //             >
  //               <NavLink className="menu-link" to={PATH.SCHEDULE}>
  //                 <span className="svg-icon menu-icon">
  //                   <FaVideo size="18" className="w-auto h-auto" />
  //                 </span>
  //                 <span className="menu-text">Schedule</span>
  //               </NavLink>
  //             </li>
  //           )}
  //           {superAdminRights() && (
  //             <>
  //               <li
  //                 className={`menu-item ${getMenuItemActive(
  //                   PATH.DHIS_LOGS,
  //                   false
  //                 )}`}
  //                 aria-haspopup="true"
  //               >
  //                 <NavLink className="menu-link" to={PATH.DHIS_LOGS}>
  //                   <span className="svg-icon menu-icon">
  //                     <FcDataConfiguration
  //                       size="18"
  //                       className="w-auto h-auto"
  //                     />
  //                   </span>
  //                   <span className="menu-text">DHIS2 Logs</span>
  //                 </NavLink>
  //               </li>
  //               <li
  //                 className={`menu-item ${getMenuItemActive(
  //                   PATH.DHIS_CONFIGURATION,
  //                   false
  //                 )}`}
  //                 aria-haspopup="true"
  //               >
  //                 <NavLink className="menu-link" to={PATH.DHIS_CONFIGURATION}>
  //                   <span className="svg-icon menu-icon">
  //                     <FcDataConfiguration
  //                       size="18"
  //                       className="w-auto h-auto"
  //                     />
  //                   </span>
  //                   <span className="menu-text">DHIS2 Configuration</span>
  //                 </NavLink>
  //               </li>

  //               {/*end::1 Level*/}

  //               {/*begin::1 Level*/}
  //               <li
  //                 className={`menu-item ${getMenuItemActive(
  //                   PATH.DHIS_UPLOAD,
  //                   false
  //                 )}`}
  //                 aria-haspopup="true"
  //               >
  //                 <NavLink className="menu-link" to={PATH.DHIS_UPLOAD}>
  //                   <span className="svg-icon menu-icon">
  //                     <FcDataConfiguration
  //                       size="18"
  //                       className="w-auto h-auto"
  //                     />
  //                   </span>
  //                   <span className="menu-text">DHIS2 Upload</span>
  //                 </NavLink>
  //               </li>
  //               <li
  //                 className={`menu-item ${getMenuItemActive(
  //                   PATH.DHIS_REQUESTS,
  //                   false
  //                 )}`}
  //                 aria-haspopup="true"
  //               >
  //                 <NavLink className="menu-link" to={PATH.DHIS_REQUESTS}>
  //                   <span className="svg-icon menu-icon">
  //                     <FcDataConfiguration
  //                       size="18"
  //                       className="w-auto h-auto"
  //                     />
  //                   </span>
  //                   <span className="menu-text">DHIS2 Requests</span>
  //                 </NavLink>
  //               </li>
  //               <li
  //                 className={`menu-item ${getMenuItemActive(
  //                   PATH.DHIS2_CONFIGURATION,
  //                   false
  //                 )}`}
  //                 aria-haspopup="true"
  //               >
  //                 <NavLink className="menu-link" to={PATH.DHIS2_CONFIGURATION}>
  //                   <span className="svg-icon menu-icon">
  //                     <FcDataConfiguration
  //                       size="18"
  //                       className="w-auto h-auto"
  //                     />
  //                   </span>
  //                   <span className="menu-text">DHIS2 Facilities</span>
  //                 </NavLink>
  //               </li>
  //               {/*end::1 Level*/}
  //             </>
  //           )}
  //         </>
  //       )}
  //       {user.data.roles[0] === "DHISViewer" && (
  //         <>
  //           {/*end::1 Level*/}
  //           {/*begin::1 Level*/}

  //           {/*begin::1 Level*/}
  //           <li
  //             className={`menu-item ${getMenuItemActive(
  //               PATH.DHIS_UPLOAD,
  //               false
  //             )}`}
  //             aria-haspopup="true"
  //           >
  //             <NavLink className="menu-link" to={PATH.DHIS_UPLOAD}>
  //               <span className="svg-icon menu-icon">
  //                 <FcDataConfiguration size="18" className="w-auto h-auto" />
  //               </span>
  //               <span className="menu-text">DHIS2 Request</span>
  //             </NavLink>
  //           </li>

  //           {/*end::1 Level*/}
  //         </>
  //       )}
  //       {console.log("resting", user.data.roles[0])}
  //       {DHIS2SuperAdmin() && (
  //         <>
  //           {/*end::1 Level*/}
  //           {/*begin::1 Level*/}

  //           {/*begin::1 Level*/}
  //           <li
  //             className={`menu-item ${getMenuItemActive(
  //               PATH.DHIS_UPLOAD,
  //               false
  //             )}`}
  //             aria-haspopup="true"
  //           >
  //             <NavLink className="menu-link" to={PATH.DHIS_UPLOAD}>
  //               <span className="svg-icon menu-icon">
  //                 <FcDataConfiguration size="18" className="w-auto h-auto" />
  //               </span>
  //               <span className="menu-text">DHIS2 Request</span>
  //             </NavLink>
  //           </li>
  //           <li
  //             className={`menu-item ${getMenuItemActive(
  //               PATH.DHIS_REQUESTS,
  //               false
  //             )}`}
  //             aria-haspopup="true"
  //           >
  //             <NavLink className="menu-link" to={PATH.DHIS_REQUESTS}>
  //               <span className="svg-icon menu-icon">
  //                 <FcDataConfiguration size="18" className="w-auto h-auto" />
  //               </span>
  //               <span className="menu-text">DHIS2 Data</span>
  //             </NavLink>
  //           </li>
  //           {/*end::1 Level*/}
  //         </>
  //       )}
  //       {user.data.roles[0] === "DHISAdmin" && (
  //         <>
  //           {/*begin::1 Level*/}
  //           <li
  //             className={`menu-item ${getMenuItemActive(
  //               PATH.DHIS_REQUESTS,
  //               false
  //             )}`}
  //             aria-haspopup="true"
  //           >
  //             <NavLink className="menu-link" to={PATH.DHIS_REQUESTS}>
  //               <span className="svg-icon menu-icon">
  //                 <FcDataConfiguration size="18" className="w-auto h-auto" />
  //               </span>
  //               <span className="menu-text">DHIS2 Requests</span>
  //             </NavLink>
  //           </li>

  //           {/*end::1 Level*/}

  //           {/*begin::1 Level*/}
  //           {/* <li
  //             className={`menu-item ${getMenuItemActive(
  //               PATH.DHIS_UPLOAD,
  //               false
  //             )}`}
  //             aria-haspopup="true"
  //           >
  //             <NavLink className="menu-link" to={PATH.DHIS_UPLOAD}>
  //               <span className="svg-icon menu-icon">
  //                 <FcDataConfiguration size="18" className="w-auto h-auto" />
  //               </span>
  //               <span className="menu-text">DHIS2 Upload</span>
  //             </NavLink>
  //           </li> */}

  //           {/*end::1 Level*/}
  //         </>
  //       )}
  //       {console.log("user?.data?.roles", user?.data?.roles)}
  //       {user.data.roles[0] === "AnalyticsManager" && (
  //         <>
  //           <li
  //             className={`menu-item ${getMenuItemActive(
  //               PATH.MAIN_DASHBOARD,
  //               false
  //             )} ${getMenuItemActive(
  //               PATH.ANC_DASHBOARD,
  //               false
  //             )} ${getMenuItemActive(PATH.IMMUNIZATION_DASHBOARD, false)}`}
  //             aria-haspopup="true"
  //           >
  //             <NavLink className="menu-link" to={PATH.MAIN_DASHBOARD}>
  //               <span className="svg-icon menu-icon">
  //                 <FaTachometerAlt size="18" className="h-auto w-auto" />
  //               </span>
  //               <span className="menu-text">Dashboard</span>
  //             </NavLink>
  //           </li>
  //           <li
  //             className={`menu-item ${getMenuItemActive(
  //               PATH.LOGGING_ACTIVITY,
  //               false
  //             )}`}
  //             aria-haspopup="true"
  //           >
  //             <NavLink className="menu-link" to={PATH.LOGGING_ACTIVITY}>
  //               <span className="svg-icon menu-icon">
  //                 <FaListUl size="18" className="w-auto h-auto" />
  //               </span>
  //               <span className="menu-text">Logging Activity</span>
  //             </NavLink>
  //           </li>
  //         </>
  //       )}
  //       <li
  //         className={`menu-item ${getMenuItemActive(
  //           PATH.ROLES_AND_PERMISSIONS,
  //           false
  //         )}`}
  //         aria-haspopup="true"
  //       >
  //         <NavLink className="menu-link" to={PATH.ROLES_AND_PERMISSIONS}>
  //           <span className="svg-icon menu-icon">
  //             <FcDataConfiguration size="18" className="w-auto h-auto" />
  //           </span>
  //           <span className="menu-text">Roles Permissions</span>
  //         </NavLink>
  //       </li>
  //     </ul>

  //     {/* end::Menu Nav */}
  //   </>
  // );
}
