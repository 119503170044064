import { LOGGING_ACTIVITY_REQUEST } from "../actions/utilities";

const INITIAL_STATE = {
  getLoggingActivityListLoading: false,
  getLoggingActivityListSuccess: false,
  getLoggingActivityListFailure: false,
  getLoggingActivityListError: null,
  LoggingActivityList: [],
  LoggingActivityListCount: 0,

  getLoggingActivityAnalyticsLoading: false,
  getLoggingActivityAnalyticsSuccess: false,
  getLoggingActivityAnalyticsFailure: false,
  getLoggingActivityAnalyticsError: null,
  LoggingActivityAnalytics: [],
  LoggingActivityAnalyticsCount: 0,

  getAllTallyValidationLoading: false,
  getAllTallyValidationSuccess: false,
  getAllTallyValidationFailure: false,
  getAllTallyValidationError: null,
  TallyValidationData: [],
  TallyValidationCount: 0,

  exportAnalyticsLoading: false,
  exportAnalyticsSuccess: false,
  exportAnalyticsFailure: false,
  exportAnalyticsError: null,
  exportAnalyticsData: null,

  chartAnalyticsLoading: false,
  chartAnalyticsSuccess: false,
  chartAnalyticsFailure: false,
  chartAnalyticsError: null,
  chartAnalyticsData: null,
};

export const loggingActivityReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGGING_ACTIVITY_REQUEST.GET_LOGGING_ACTIVITY_LIST_REQUEST:
      return {
        ...state,
        getLoggingActivityListLoading: true,
        getLoggingActivityListSuccess: false,
        getLoggingActivityListFailure: false,
        getLoggingActivityListError: null,
        LoggingActivityList: [],
        LoggingActivityListCount: 0,
      };
    case LOGGING_ACTIVITY_REQUEST.GET_LOGGING_ACTIVITY_LIST_FAILURE:
      return {
        ...state,
        getLoggingActivityListLoading: false,
        getLoggingActivityListSuccess: false,
        getLoggingActivityListFailure: true,
        getLoggingActivityListError: action.payload,
      };
    case LOGGING_ACTIVITY_REQUEST.GET_LOGGING_ACTIVITY_LIST_SUCCESS:
      return {
        ...state,
        getLoggingActivityListLoading: false,
        getLoggingActivityListSuccess: true,
        getLoggingActivityListFailure: false,
        getLoggingActivityListError: null,
        LoggingActivityList: action.payload,
        LoggingActivityListCount: action.info.count,
      };

    case LOGGING_ACTIVITY_REQUEST.GET_LOGGING_ACTIVITY_ANALYTICS_REQUEST:
      return {
        ...state,
        getLoggingActivityAnalyticsLoading: true,
        getLoggingActivityAnalyticsSuccess: false,
        getLoggingActivityAnalyticsFailure: false,
        getLoggingActivityAnalyticsError: null,
        LoggingActivityAnalytics: [],
        LoggingActivityAnalyticsCount: 0,
      };
    case LOGGING_ACTIVITY_REQUEST.GET_LOGGING_ACTIVITY_ANALYTICS_FAILURE:
      return {
        ...state,
        getLoggingActivityAnalyticsLoading: false,
        getLoggingActivityAnalyticsSuccess: false,
        getLoggingActivityAnalyticsFailure: true,
        getLoggingActivityAnalyticsError: action.payload,
      };
    case LOGGING_ACTIVITY_REQUEST.GET_LOGGING_ACTIVITY_ANALYTICS_SUCCESS:
      return {
        ...state,
        getLoggingActivityAnalyticsLoading: false,
        getLoggingActivityAnalyticsSuccess: true,
        getLoggingActivityAnalyticsFailure: false,
        getLoggingActivityAnalyticsError: null,
        LoggingActivityAnalytics: action.payload,
        LoggingActivityAnalyticsCount: action.info.count,
      };

    case LOGGING_ACTIVITY_REQUEST.GET_ALL_TALLY_VALIDATION_REQUEST:
      return {
        ...state,
        getAllTallyValidationLoading: true,
        getAllTallyValidationSuccess: false,
        getAllTallyValidationFailure: false,
        getAllTallyValidationError: null,
        TallyValidationData: [],
        TallyValidationCount: 0,
      };
    case LOGGING_ACTIVITY_REQUEST.GET_ALL_TALLY_VALIDATION_FAILURE:
      return {
        ...state,
        getAllTallyValidationLoading: false,
        getAllTallyValidationSuccess: false,
        getAllTallyValidationFailure: true,
        getAllTallyValidationError: action.payload,
      };
    case LOGGING_ACTIVITY_REQUEST.GET_ALL_TALLY_VALIDATION_SUCCESS:
      return {
        ...state,
        getAllTallyValidationLoading: false,
        getAllTallyValidationSuccess: true,
        getAllTallyValidationFailure: false,
        getAllTallyValidationError: null,
        TallyValidationData: action.payload,
        TallyValidationCount: action.info.count,
      };

    case LOGGING_ACTIVITY_REQUEST.GET_LOGGING_ACTIVITY_ANALYTICS_EXPORT_REQUEST:
      return {
        ...state,
        exportAnalyticsLoading: true,
        exportAnalyticsSuccess: false,
        exportAnalyticsFailure: false,
        exportAnalyticsError: null,
        exportAnalyticsData: null,
      };
    case LOGGING_ACTIVITY_REQUEST.GET_LOGGING_ACTIVITY_ANALYTICS_EXPORT_FAILURE:
      return {
        ...state,
        exportAnalyticsLoading: false,
        exportAnalyticsSuccess: false,
        exportAnalyticsFailure: true,
        exportAnalyticsError: action.payload,
      };
    case LOGGING_ACTIVITY_REQUEST.GET_LOGGING_ACTIVITY_ANALYTICS_EXPORT_SUCCESS:
      return {
        ...state,
        exportAnalyticsLoading: false,
        exportAnalyticsSuccess: true,
        exportAnalyticsFailure: false,
        exportAnalyticsError: null,
        exportAnalyticsData: action.payload,
      };

    case LOGGING_ACTIVITY_REQUEST.GET_LOGGING_ACTIVITY_ANALYTICS_CHART_REQUEST:
      return {
        ...state,
        chartAnalyticsLoading: true,
        chartAnalyticsSuccess: false,
        chartAnalyticsFailure: false,
        chartAnalyticsError: null,
        chartAnalyticsData: null,
      };
    case LOGGING_ACTIVITY_REQUEST.GET_LOGGING_ACTIVITY_ANALYTICS_CHART_FAILURE:
      return {
        ...state,
        chartAnalyticsLoading: false,
        chartAnalyticsSuccess: false,
        chartAnalyticsFailure: true,
        chartAnalyticsError: action.payload,
      };
    case LOGGING_ACTIVITY_REQUEST.GET_LOGGING_ACTIVITY_ANALYTICS_CHART_SUCCESS:
      return {
        ...state,
        chartAnalyticsLoading: false,
        chartAnalyticsSuccess: true,
        chartAnalyticsFailure: false,
        chartAnalyticsError: null,
        chartAnalyticsData: action.payload,
      };

    default:
      return state;
  }
};
