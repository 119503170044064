import { MAIN_DASHBOARD } from "../actions/utilities";

const INITIAL_STATE = {
  getStatsLoading: false,
  getStatsSuccess: false,
  getStatsFailure: false,
  getStatsError: null,
  mainDashboardStats: [],

  getVisitSummaryStatsLoading: false,
  getVisitSummaryStatsSuccess: false,
  getVisitSummaryStatsFailure: false,
  getVisitSummaryStatsError: null,
  VisitSummaryStatsData: [],

  getModernBirthSpacingSummaryStatsLoading: false,
  getModernBirthSpacingSummaryStatsSuccess: false,
  getModernBirthSpacingSummaryStatsFailure: false,
  getModernBirthSpacingSummaryStatsError: null,
  ModernBirthSpacingSummaryData: [],

  getAnemiaandTreatmentSummaryStatsLoading: false,
  getAnemiaandTreatmentSummaryStatsSuccess: false,
  getAnemiaandTreatmentSummaryStatsFailure: false,
  getAnemiaandTreatmentSummaryStatsError: null,
  AnemiaandTreatmentSummaryData: [],

  getDiarrhoeaSummaryStatsLoading: false,
  getDiarrhoeaSummaryStatsSuccess: false,
  getDiarrhoeaSummaryStatsFailure: false,
  getDiarrhoeaSummaryStatsError: null,
  DiarrhoeaSummaryData: [],

  getMalariaSummaryStatsLoading: false,
  getMalariaSummaryStatsSuccess: false,
  getMalariaSummaryStatsFailure: false,
  getMalariaSummaryStatsError: null,
  MalariaSummaryData: [],

  getUnderFiveMalariaSummaryStatsLoading: false,
  getUnderFiveMalariaSummaryStatsSuccess: false,
  getUnderFiveMalariaSummaryStatsFailure: false,
  getUnderFiveMalariaSummaryStatsError: null,
  UnderFiveMalariaSummaryData: [],

  getPlasmodiumSpeciesSummaryStatsLoading: false,
  getPlasmodiumSpeciesSummaryStatsSuccess: false,
  getPlasmodiumSpeciesSummaryStatsFailure: false,
  getPlasmodiumSpeciesSummaryStatsError: null,
  PlasmodiumSpeciesSummaryData: [],

  getRapeAndGBVCasesSummaryStatsLoading: false,
  getRapeAndGBVCasesSummaryStatsSuccess: false,
  getRapeAndGBVCasesSummaryStatsFailure: false,
  getRapeAndGBVCasesSummaryStatsError: null,
  RapeAndGBVCasesSummaryData: [],

  getOutcomeSummaryStatsLoading: false,
  getOutcomeSummaryStatsSuccess: false,
  getOutcomeSummaryStatsFailure: false,
  getOutcomeSummaryStatsError: null,
  OutcomeSummaryData: [],

  getLDMImmunizationLoading: false,
  getLDMImmunizationSuccess: false,
  getLDMImmunizationFailure: false,
  getLDMImmunizationError: null,
  LDMImmunizationData: [],

  getLDMMotherAtDischargeLoading: false,
  getLDMMotherAtDischargeSuccess: false,
  getLDMMotherAtDischargeFailure: false,
  getLDMMotherAtDischargeError: null,
  LDMMotherAtDischargeData: [],

  getLDMBabyAtDischargeLoading: false,
  getLDMBabyAtDischargeSuccess: false,
  getLDMBabyAtDischargeFailure: false,
  getLDMBabyAtDischargeError: null,
  LDMBabyAtDischargeData: [],

  getLDMPMTCTSummaryLoading: false,
  getLDMPMTCTSummarySuccess: false,
  getLDMPMTCTSummaryFailure: false,
  getLDMPMTCTSummaryError: null,
  LDMPMTCTSummaryData: [],

  getLDMARTProphylaxisLoading: false,
  getLDMARTProphylaxisSuccess: false,
  getLDMARTProphylaxisFailure: false,
  getLDMARTProphylaxisError: null,
  LDMARTProphylaxisData: [],

  getLDReferralSummaryLoading: false,
  getLDReferralSummarySuccess: false,
  getLDReferralSummaryFailure: false,
  getLDReferralSummaryError: null,
  LDReferralSummaryData: [],



  getHistoryGraphLoading: false,
  getHistoryGraphSuccess: false,
  getHistoryGraphFailure: false,
  getHistoryGraphError: null,
  historyGraphData: [],

  getCaseProfileLoading: false,
  getCaseProfileSuccess: false,
  getCaseProfileFailure: false,
  getCaseProfileError: null,
  caseProfileData: [],
  totalcaseProfileCount: 0,

  getSmsNudgesListLoading: false,
  getSmsNudgesListSuccess: false,
  getSmsNudgesListFailure: false,
  getSmsNudgesListError: null,
  SmsNudgesListData: [],

  getIvrListLoading: false,
  getIvrListSuccess: false,
  getIvrListFailure: false,
  getIvrListError: null,
  IvrListData: [],

  getNotifyVisitorsListLoading: false,
  getNotifyVisitorsListSuccess: false,
  getNotifyVisitorsListFailure: false,
  getNotifyVisitorsListError: null,
  notifyVisitorsListData: [],

  getAllCountLoading: false,
  getAllCountSuccess: false,
  getAllCountFailure: false,
  getAllCountError: null,
  allCountRecords: [],

  exportHistoryGraphDataLoading: false,
  exportHistoryGraphDataSuccess: false,
  exportHistoryGraphDataFailure: false,
  exportHistoryGraphDataError: null,
  exportHistoryGraphData: null,

  exportStatsDataLoading: false,
  exportStatsDataSuccess: false,
  exportStatsDataFailure: false,
  exportStatsDataError: null,
  exportStatsData: null,

  exportNotifyUserDataLoading: false,
  exportNotifyUserDataSuccess: false,
  exportNotifyUserDataFailure: false,
  exportNotifyUserDataError: null,
  exportNotifyUserData: null,


  exportIVRDataLoading: false,
  exportIVRDataSuccess: false,
  exportIVRDataFailure: false,
  exportIVRDataError: null,
  exportIVRData: null,

  sendNotifiedUserManualMessageLoading: false,
  sendNotifiedUserManualMessageSuccess: false,
  sendNotifiedUserManualMessageFailure: false,
  sendNotifiedUserManualMessageError: null,
};

export const mainDashboardReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MAIN_DASHBOARD.GET_LDM_REFERRAL_SUMMARY_REQUEST:
      return {
        ...state,
        getLDReferralSummaryLoading: true,
        getLDReferralSummarySuccess: false,
        getLDReferralSummaryFailure: false,
        getLDReferralSummaryError: null,
      };
    case MAIN_DASHBOARD.GET_LDM_REFERRAL_SUMMARY_FAILURE:
      return {
        ...state,
        getLDReferralSummaryLoading: false,
        getLDReferralSummarySuccess: false,
        getLDReferralSummaryFailure: true,
        getLDReferralSummaryError: action.payload,
      };
    case MAIN_DASHBOARD.GET_LDM_REFERRAL_SUMMARY_SUCCESS:
      return {
        ...state,
        getLDReferralSummaryLoading: false,
        getLDReferralSummarySuccess: true,
        getLDReferralSummaryFailure: false,
        getLDReferralSummaryError: null,
        LDReferralSummaryData: action.payload,
      };

    case MAIN_DASHBOARD.GET_LDM_ART_PROPHYLAXIS_REQUEST:
      return {
        ...state,
        getLDMARTProphylaxisLoading: true,
        getLDMARTProphylaxisSuccess: false,
        getLDMARTProphylaxisFailure: false,
        getLDMARTProphylaxisError: null,
      };
    case MAIN_DASHBOARD.GET_LDM_ART_PROPHYLAXIS_FAILURE:
      return {
        ...state,
        getLDMARTProphylaxisLoading: false,
        getLDMARTProphylaxisSuccess: false,
        getLDMARTProphylaxisFailure: true,
        getLDMARTProphylaxisError: action.payload,
      };
    case MAIN_DASHBOARD.GET_LDM_ART_PROPHYLAXIS_SUCCESS:
      return {
        ...state,
        getLDMARTProphylaxisLoading: false,
        getLDMARTProphylaxisSuccess: true,
        getLDMARTProphylaxisFailure: false,
        getLDMARTProphylaxisError: null,
        LDMARTProphylaxisData: action.payload,
      };

    case MAIN_DASHBOARD.GET_LDM_PMTCT_SUMMARY_REQUEST:
      return {
        ...state,
        getLDMPMTCTSummaryLoading: true,
        getLDMPMTCTSummarySuccess: false,
        getLDMPMTCTSummaryFailure: false,
        getLDMPMTCTSummaryError: null,
      };
    case MAIN_DASHBOARD.GET_LDM_PMTCT_SUMMARY_FAILURE:
      return {
        ...state,
        getLDMPMTCTSummaryLoading: false,
        getLDMPMTCTSummarySuccess: false,
        getLDMPMTCTSummaryFailure: true,
        getLDMPMTCTSummaryError: action.payload,
      };
    case MAIN_DASHBOARD.GET_LDM_PMTCT_SUMMARY_SUCCESS:
      return {
        ...state,
        getLDMPMTCTSummaryLoading: false,
        getLDMPMTCTSummarySuccess: true,
        getLDMPMTCTSummaryFailure: false,
        getLDMPMTCTSummaryError: null,
        LDMPMTCTSummaryData: action.payload,
      };
    case MAIN_DASHBOARD.GET_LDM_MOTHER_DISCHARGE_REQUEST:
      return {
        ...state,
        getLDMMotherAtDischargeLoading: true,
        getLDMMotherAtDischargeSuccess: false,
        getLDMMotherAtDischargeFailure: false,
        getLDMMotherAtDischargeError: null,
      };
    case MAIN_DASHBOARD.GET_LDM_MOTHER_DISCHARGE_FAILURE:
      return {
        ...state,
        getLDMMotherAtDischargeLoading: false,
        getLDMMotherAtDischargeSuccess: false,
        getLDMMotherAtDischargeFailure: true,
        getLDMMotherAtDischargeError: action.payload,
      };
    case MAIN_DASHBOARD.GET_LDM_MOTHER_DISCHARGE_SUCCESS:
      return {
        ...state,
        getLDMMotherAtDischargeLoading: false,
        getLDMMotherAtDischargeSuccess: true,
        getLDMMotherAtDischargeFailure: false,
        getLDMMotherAtDischargeError: null,
        LDMMotherAtDischargeData: action.payload,
      };

    case MAIN_DASHBOARD.GET_LDM_BABY_DISCHARGE_REQUEST:
      return {
        ...state,
        getLDMBabyAtDischargeLoading: true,
        getLDMBabyAtDischargeSuccess: false,
        getLDMBabyAtDischargeFailure: false,
        getLDMBabyAtDischargeError: null,
      };
    case MAIN_DASHBOARD.GET_LDM_BABY_DISCHARGE_FAILURE:
      return {
        ...state,
        getLDMBabyAtDischargeLoading: false,
        getLDMBabyAtDischargeSuccess: false,
        getLDMBabyAtDischargeFailure: true,
        getLDMBabyAtDischargeError: action.payload,
      };
    case MAIN_DASHBOARD.GET_LDM_BABY_DISCHARGE_SUCCESS:
      return {
        ...state,
        getLDMBabyAtDischargeLoading: false,
        getLDMBabyAtDischargeSuccess: true,
        getLDMBabyAtDischargeFailure: false,
        getLDMBabyAtDischargeError: null,
        LDMBabyAtDischargeData: action.payload,
      };

    case MAIN_DASHBOARD.GET_LDM_IMMUNIZATION_REQUEST:
      return {
        ...state,
        getLDMImmunizationLoading: true,
        getLDMImmunizationSuccess: false,
        getLDMImmunizationFailure: false,
        getLDMImmunizationError: null,
      };
    case MAIN_DASHBOARD.GET_LDM_IMMUNIZATION_FAILURE:
      return {
        ...state,
        getLDMImmunizationLoading: false,
        getLDMImmunizationSuccess: false,
        getLDMImmunizationFailure: true,
        getLDMImmunizationError: action.payload,
      };
    case MAIN_DASHBOARD.GET_LDM_IMMUNIZATION_SUCCESS:
      return {
        ...state,
        getLDMImmunizationLoading: false,
        getLDMImmunizationSuccess: true,
        getLDMImmunizationFailure: false,
        getLDMImmunizationError: null,
        LDMImmunizationData: action.payload,
      };

    case MAIN_DASHBOARD.GET_OUTCOME_SUMMARY_STATS_REQUEST:
      return {
        ...state,
        getOutcomeSummaryStatsLoading: true,
        getOutcomeSummaryStatsSuccess: false,
        getOutcomeSummaryStatsFailure: false,
        getOutcomeSummaryStatsError: null,
      };
    case MAIN_DASHBOARD.GET_OUTCOME_SUMMARY_STATS_FAILURE:
      return {
        ...state,
        getOutcomeSummaryStatsLoading: false,
        getOutcomeSummaryStatsSuccess: false,
        getOutcomeSummaryStatsFailure: true,
        getOutcomeSummaryStatsError: action.payload,
      };
    case MAIN_DASHBOARD.GET_OUTCOME_SUMMARY_STATS_SUCCESS:
      return {
        ...state,
        getOutcomeSummaryStatsLoading: false,
        getOutcomeSummaryStatsSuccess: true,
        getOutcomeSummaryStatsFailure: false,
        getOutcomeSummaryStatsError: null,
        OutcomeSummaryData: action.payload,
      };

    case MAIN_DASHBOARD.GET_RAPE_AND_GBV_CASES_SUMMARY_STATS_REQUEST:
      return {
        ...state,
        getRapeAndGBVCasesSummaryStatsLoading: true,
        getRapeAndGBVCasesSummaryStatsSuccess: false,
        getRapeAndGBVCasesSummaryStatsFailure: false,
        getRapeAndGBVCasesSummaryStatsError: null,
      };
    case MAIN_DASHBOARD.GET_RAPE_AND_GBV_CASES_SUMMARY_STATS_FAILURE:
      return {
        ...state,
        getRapeAndGBVCasesSummaryStatsLoading: false,
        getRapeAndGBVCasesSummaryStatsSuccess: false,
        getRapeAndGBVCasesSummaryStatsFailure: true,
        getRapeAndGBVCasesSummaryStatsError: action.payload,
      };
    case MAIN_DASHBOARD.GET_RAPE_AND_GBV_CASES_SUMMARY_STATS_SUCCESS:
      return {
        ...state,
        getRapeAndGBVCasesSummaryStatsLoading: false,
        getRapeAndGBVCasesSummaryStatsSuccess: true,
        getRapeAndGBVCasesSummaryStatsFailure: false,
        getRapeAndGBVCasesSummaryStatsError: null,
        RapeAndGBVCasesSummaryData: action.payload,
      };
    case MAIN_DASHBOARD.GET_VISIT_SUMMARY_STATS_REQUEST:
      return {
        ...state,
        getVisitSummaryLoading: true,
        getVisitSummarySuccess: false,
        getVisitSummaryFailure: false,
        getVisitSummaryError: null,
      };
    case MAIN_DASHBOARD.GET_VISIT_SUMMARY_STATS_FAILURE:
      return {
        ...state,
        getVisitSummaryLoading: false,
        getVisitSummarySuccess: false,
        getVisitSummaryFailure: true,
        getVisitSummaryError: action.payload,
      };
    case MAIN_DASHBOARD.GET_VISIT_SUMMARY_STATS_SUCCESS:
      return {
        ...state,
        getVisitSummaryLoading: false,
        getVisitSummarySuccess: true,
        getVisitSummaryFailure: false,
        getVisitSummaryError: null,
        VisitSummaryStatsData: action.payload,
      };
    case MAIN_DASHBOARD.GET_MODERN_BIRTH_SPACING_STATS_REQUEST:
      return {
        ...state,
        getModernBirthSpacingSummaryStatsLoading: true,
        getModernBirthSpacingSummaryStatsSuccess: false,
        getModernBirthSpacingSummaryStatsFailure: false,
        getModernBirthSpacingSummaryStatsError: null,
      };
    case MAIN_DASHBOARD.GET_MODERN_BIRTH_SPACING_STATS_FAILURE:
      return {
        ...state,
        getModernBirthSpacingSummaryStatsLoading: false,
        getModernBirthSpacingSummaryStatsSuccess: false,
        getModernBirthSpacingSummaryStatsFailure: true,
        getModernBirthSpacingSummaryStatsError: action.payload,
      };
    case MAIN_DASHBOARD.GET_MODERN_BIRTH_SPACING_STATS_SUCCESS:
      return {
        ...state,
        getModernBirthSpacingSummaryStatsLoading: false,
        getModernBirthSpacingSummaryStatsSuccess: true,
        getModernBirthSpacingSummaryStatsFailure: false,
        getModernBirthSpacingSummaryStatsError: null,
        ModernBirthSpacingSummaryData: action.payload,
      };
    case MAIN_DASHBOARD.GET_ANEMIAAND_TREATMENT_SUMMARY_STATS_REQUEST:
      return {
        ...state,
        getAnemiaandTreatmentSummaryStatsLoading: true,
        getAnemiaandTreatmentSummaryStatsSuccess: false,
        getAnemiaandTreatmentSummaryStatsFailure: false,
        getAnemiaandTreatmentSummaryStatsError: null,
      };
    case MAIN_DASHBOARD.GET_ANEMIAAND_TREATMENT_SUMMARY_STATS_FAILURE:
      return {
        ...state,
        getAnemiaandTreatmentSummaryStatsLoading: false,
        getAnemiaandTreatmentSummaryStatsSuccess: false,
        getAnemiaandTreatmentSummaryStatsFailure: true,
        getAnemiaandTreatmentSummaryStatsError: action.payload,
      };
    case MAIN_DASHBOARD.GET_ANEMIAAND_TREATMENT_SUMMARY_STATS_SUCCESS:
      return {
        ...state,
        getAnemiaandTreatmentSummaryStatsLoading: false,
        getAnemiaandTreatmentSummaryStatsSuccess: true,
        getAnemiaandTreatmentSummaryStatsFailure: false,
        getAnemiaandTreatmentSummaryStatsError: null,
        AnemiaandTreatmentSummaryData: action.payload,
      };
    case MAIN_DASHBOARD.GET_DIARRHOEA_SUMMARY_STATS_REQUEST:
      return {
        ...state,
        getDiarrhoeaSummaryStatsLoading: true,
        getDiarrhoeaSummaryStatsSuccess: false,
        getDiarrhoeaSummaryStatsFailure: false,
        getDiarrhoeaSummaryStatsError: null,
      };
    case MAIN_DASHBOARD.GET_DIARRHOEA_SUMMARY_STATS_FAILURE:
      return {
        ...state,
        getDiarrhoeaSummaryStatsLoading: false,
        getDiarrhoeaSummaryStatsSuccess: false,
        getDiarrhoeaSummaryStatsFailure: true,
        getDiarrhoeaSummaryStatsError: action.payload,
      };
    case MAIN_DASHBOARD.GET_DIARRHOEA_SUMMARY_STATS_SUCCESS:
      return {
        ...state,
        getDiarrhoeaSummaryStatsLoading: false,
        getDiarrhoeaSummaryStatsSuccess: true,
        getDiarrhoeaSummaryStatsFailure: false,
        getDiarrhoeaSummaryStatsError: null,
        DiarrhoeaSummaryData: action.payload,
      };
    case MAIN_DASHBOARD.GET_MALARIA_SUMMARY_STATS_REQUEST:
      return {
        ...state,
        getMalariaSummaryStatsLoading: true,
        getMalariaSummaryStatsSuccess: false,
        getMalariaSummaryStatsFailure: false,
        getMalariaSummaryStatsError: null,
      };
    case MAIN_DASHBOARD.GET_MALARIA_SUMMARY_STATS_FAILURE:
      return {
        ...state,
        getMalariaSummaryStatsLoading: false,
        getMalariaSummaryStatsSuccess: false,
        getMalariaSummaryStatsFailure: true,
        getMalariaSummaryStatsError: action.payload,
      };
    case MAIN_DASHBOARD.GET_MALARIA_SUMMARY_STATS_SUCCESS:
      return {
        ...state,
        getMalariaSummaryStatsLoading: false,
        getMalariaSummaryStatsSuccess: true,
        getMalariaSummaryStatsFailure: false,
        getMalariaSummaryStatsError: null,
        MalariaSummaryData: action.payload,
      };
    case MAIN_DASHBOARD.GET_UNDER_FIVE_MALARIA_SUMMARY_STATS_REQUEST:
      return {
        ...state,
        getUnderFiveMalariaSummaryStatsLoading: true,
        getUnderFiveMalariaSummaryStatsSuccess: false,
        getUnderFiveMalariaSummaryStatsFailure: false,
        getUnderFiveMalariaSummaryStatsError: null,
      };
    case MAIN_DASHBOARD.GET_UNDER_FIVE_MALARIA_SUMMARY_STATS_FAILURE:
      return {
        ...state,
        getUnderFiveMalariaSummaryStatsLoading: false,
        getUnderFiveMalariaSummaryStatsSuccess: false,
        getUnderFiveMalariaSummaryStatsFailure: true,
        getUnderFiveMalariaSummaryStatsError: action.payload,
      };
    case MAIN_DASHBOARD.GET_UNDER_FIVE_MALARIA_SUMMARY_STATS_SUCCESS:
      return {
        ...state,
        getUnderFiveMalariaSummaryStatsLoading: false,
        getUnderFiveMalariaSummaryStatsSuccess: true,
        getUnderFiveMalariaSummaryStatsFailure: false,
        getUnderFiveMalariaSummaryStatsError: null,
        UnderFiveMalariaSummaryData: action.payload,
      };
    case MAIN_DASHBOARD.GET_PLASMODIUAM_SPECIES_SUMMARY_STATS_REQUEST:
      return {
        ...state,
        getPlasmodiumSpeciesSummaryStatsLoading: true,
        getPlasmodiumSpeciesSummaryStatsSuccess: false,
        getPlasmodiumSpeciesSummaryStatsFailure: false,
        getPlasmodiumSpeciesSummaryStatsError: null,
      };
    case MAIN_DASHBOARD.GET_PLASMODIUAM_SPECIES_SUMMARY_STATS_FAILURE:
      return {
        ...state,
        getPlasmodiumSpeciesSummaryStatsLoading: false,
        getPlasmodiumSpeciesSummaryStatsSuccess: false,
        getPlasmodiumSpeciesSummaryStatsFailure: true,
        getPlasmodiumSpeciesSummaryStatsError: action.payload,
      };
    case MAIN_DASHBOARD.GET_PLASMODIUAM_SPECIES_SUMMARY_STATS_SUCCESS:
      return {
        ...state,
        getPlasmodiumSpeciesSummaryStatsLoading: false,
        getPlasmodiumSpeciesSummaryStatsSuccess: true,
        getPlasmodiumSpeciesSummaryStatsFailure: false,
        getPlasmodiumSpeciesSummaryStatsError: null,
        PlasmodiumSpeciesSummaryData: action.payload,
      };
    ////
    case MAIN_DASHBOARD.GET_STATS_REQUEST:
      return {
        ...state,
        getStatsLoading: true,
        getStatsSuccess: false,
        getStatsFailure: false,
        getStatsError: null,
      };
    case MAIN_DASHBOARD.GET_STATS_FAILURE:
      return {
        ...state,
        getStatsLoading: false,
        getStatsSuccess: false,
        getStatsFailure: true,
        getStatsError: action.payload,
      };
    case MAIN_DASHBOARD.GET_STATS_SUCCESS:
      return {
        ...state,
        getStatsLoading: false,
        getStatsSuccess: true,
        getStatsFailure: false,
        getStatsError: null,
        mainDashboardStats: action.payload,
      };
    case MAIN_DASHBOARD.GET_HISTORY_GRAPH_REQUEST:
      return {
        ...state,
        getHistoryGraphLoading: true,
        getHistoryGraphSuccess: false,
        getHistoryGraphFailure: false,
        getHistoryGraphError: null,
        historyGraphData: [],
      };
    case MAIN_DASHBOARD.GET_HISTORY_GRAPH_FAILURE:
      return {
        ...state,
        getHistoryGraphLoading: false,
        getHistoryGraphSuccess: false,
        getHistoryGraphFailure: true,
        getHistoryGraphError: action.payload,
      };
    case MAIN_DASHBOARD.GET_HISTORY_GRAPH_SUCCESS:
      return {
        ...state,
        getHistoryGraphLoading: false,
        getHistoryGraphSuccess: true,
        getHistoryGraphFailure: false,
        getHistoryGraphError: null,
        historyGraphData: action.payload,
      };
    case MAIN_DASHBOARD.GET_CASE_PROFILE_TABLE_REQUEST:
      return {
        ...state,
        getCaseProfileLoading: true,
        getCaseProfileSuccess: false,
        getCaseProfileFailure: false,
        getCaseProfileError: null,
      };
    case MAIN_DASHBOARD.GET_CASE_PROFILE_TABLE_FAILURE:
      return {
        ...state,
        getCaseProfileLoading: false,
        getCaseProfileSuccess: false,
        getCaseProfileFailure: true,
        getCaseProfileError: action.payload,
      };
    case MAIN_DASHBOARD.GET_CASE_PROFILE_TABLE_SUCCESS:
      return {
        ...state,
        getCaseProfileLoading: false,
        getCaseProfileSuccess: true,
        getCaseProfileFailure: false,
        getCaseProfileError: null,
        caseProfileData: action.payload,
        totalcaseProfileCount: action.info.count,
      };

    case MAIN_DASHBOARD.GET_IVR_LIST_REQUEST:
      return {
        ...state,
        getIvrListLoading: true,
        getIvrListSuccess: false,
        getIvrListFailure: false,
        getIvrListError: null,
        IvrListData: [],
      };
    case MAIN_DASHBOARD.GET_IVR_LIST_FAILURE:
      return {
        ...state,
        getIvrListLoading: false,
        getIvrListSuccess: false,
        getIvrListFailure: true,
        getIvrListError: action.payload,
      };
    case MAIN_DASHBOARD.GET_IVR_LIST_SUCCESS:
      return {
        ...state,
        getIvrListLoading: false,
        getIvrListSuccess: true,
        getIvrListFailure: false,
        getIvrListError: null,
        IvrListData: action.payload,
        totalIVRCount: action.info.count,
      };

    case MAIN_DASHBOARD.GET_SMS_NUDGES_LIST_REQUEST:
      return {
        ...state,
        getSmsNudgesListLoading: true,
        getSmsNudgesListSuccess: false,
        getSmsNudgesListFailure: false,
        getSmsNudgesListError: null,
        SmsNudgesListData: [],
      };
    case MAIN_DASHBOARD.GET_SMS_NUDGES_LIST_FAILURE:
      return {
        ...state,
        getSmsNudgesListLoading: false,
        getSmsNudgesListSuccess: false,
        getSmsNudgesListFailure: true,
        getSmsNudgesListError: action.payload,
      };
    case MAIN_DASHBOARD.GET_SMS_NUDGES_LIST_SUCCESS:
      return {
        ...state,
        getSmsNudgesListLoading: false,
        getSmsNudgesListSuccess: true,
        getSmsNudgesListFailure: false,
        getSmsNudgesListError: null,
        SmsNudgesListData: action.payload,
        totalSmsNudgesCount: action.info.count,
      };

    case MAIN_DASHBOARD.GET_NOTIFY_VISITORS_LIST_REQUEST:
      return {
        ...state,
        getNotifyVisitorsListLoading: true,
        getNotifyVisitorsListSuccess: false,
        getNotifyVisitorsListFailure: false,
        getNotifyVisitorsListError: null,
        notifyVisitorsListData: [],
      };
    case MAIN_DASHBOARD.GET_NOTIFY_VISITORS_LIST_FAILURE:
      return {
        ...state,
        getNotifyVisitorsListLoading: false,
        getNotifyVisitorsListSuccess: false,
        getNotifyVisitorsListFailure: true,
        getNotifyVisitorsListError: action.payload,
      };
    case MAIN_DASHBOARD.GET_NOTIFY_VISITORS_LIST_SUCCESS:
      return {
        ...state,
        getNotifyVisitorsListLoading: false,
        getNotifyVisitorsListSuccess: true,
        getNotifyVisitorsListFailure: false,
        getNotifyVisitorsListError: null,
        notifyVisitorsListData: action.payload,
        totalNotifyVisitorsCount: action.info.count,
      };

    case MAIN_DASHBOARD.GET_ALL_COUNT_REQUEST:
      return {
        ...state,
        getAllCountLoading: true,
        getAllCountSuccess: false,
        getAllCountFailure: false,
        getAllCountError: null,
        allCountRecords: [],
      };
    case MAIN_DASHBOARD.GET_ALL_COUNT_FAILURE:
      return {
        ...state,
        getAllCountLoading: false,
        getAllCountSuccess: false,
        getAllCountFailure: true,
        getAllCountError: action.payload,
      };
    case MAIN_DASHBOARD.GET_ALL_COUNT_SUCCESS:
      return {
        ...state,
        getAllCountLoading: false,
        getAllCountSuccess: true,
        getAllCountFailure: false,
        getAllCountError: null,
        allCountRecords: action.payload,
      };
    case MAIN_DASHBOARD.EXPORT_HISTORY_GRAPH_DATA_REQUEST:
      return {
        ...state,
        exportHistoryGraphDataLoading: true,
        exportHistoryGraphDataSuccess: false,
        exportHistoryGraphDataFailure: false,
        exportHistoryGraphDataError: null,
        exportHistoryGraphData: null,
      };
    case MAIN_DASHBOARD.EXPORT_HISTORY_GRAPH_DATA_FAILURE:
      return {
        ...state,
        exportHistoryGraphDataLoading: false,
        exportHistoryGraphDataSuccess: false,
        exportHistoryGraphDataFailure: true,
        exportHistoryGraphDataError: action.payload,
      };
    case MAIN_DASHBOARD.EXPORT_HISTORY_GRAPH_DATA_SUCCESS:
      return {
        ...state,
        exportHistoryGraphDataLoading: false,
        exportHistoryGraphDataSuccess: true,
        exportHistoryGraphDataFailure: false,
        exportHistoryGraphDataError: null,
        exportHistoryGraphData: action.payload,
      };
    case MAIN_DASHBOARD.EXPORT_STATS_DATA_REQUEST:
      return {
        ...state,
        exportStatsDataLoading: true,
        exportStatsDataSuccess: false,
        exportStatsDataFailure: false,
        exportStatsDataError: null,
        exportStatsData: null,
      };
    case MAIN_DASHBOARD.EXPORT_STATS_DATA_FAILURE:
      return {
        ...state,
        exportStatsDataLoading: false,
        exportStatsDataSuccess: false,
        exportStatsDataFailure: true,
        exportStatsDataError: action.payload,
      };
    case MAIN_DASHBOARD.EXPORT_STATS_DATA_SUCCESS:
      return {
        ...state,
        exportStatsDataLoading: false,
        exportStatsDataSuccess: true,
        exportStatsDataFailure: false,
        exportStatsDataError: null,
        exportStatsData: action.payload,
      };

    case MAIN_DASHBOARD.EXPORT_IVR_DATA_REQUEST:
      return {
        ...state,
        exportIVRDataLoading: true,
        exportIVRDataSuccess: false,
        exportIVRDataFailure: false,
        exportIVRDataError: null,
        exportIVRData: null,
      };
    case MAIN_DASHBOARD.EXPORT_IVR_DATA_FAILURE:
      return {
        ...state,
        exportIVRDataLoading: false,
        exportIVRDataSuccess: false,
        exportIVRDataFailure: true,
        exportIVRDataError: action.payload,
        exportIVRData: null,
      };
    case MAIN_DASHBOARD.EXPORT_IVR_DATA_SUCCESS:
      return {
        ...state,
        exportIVRDataLoading: false,
        exportIVRDataSuccess: true,
        exportIVRDataFailure: false,
        exportIVRDataError: null,
        exportIVRData: action.payload,
      };

    case MAIN_DASHBOARD.EXPORT_NOTIFY_USER_DATA_REQUEST:
      return {
        ...state,
        exportNotifyUserDataLoading: true,
        exportNotifyUserDataSuccess: false,
        exportNotifyUserDataFailure: false,
        exportNotifyUserDataError: null,
        exportNotifyUserData: null,
      };
    case MAIN_DASHBOARD.EXPORT_NOTIFY_USER_DATA_FAILURE:
      return {
        ...state,
        exportNotifyUserDataLoading: false,
        exportNotifyUserDataSuccess: false,
        exportNotifyUserDataFailure: true,
        exportNotifyUserDataError: action.payload,
      };
    case MAIN_DASHBOARD.EXPORT_NOTIFY_USER_DATA_SUCCESS:
      return {
        ...state,
        exportNotifyUserDataLoading: false,
        exportNotifyUserDataSuccess: true,
        exportNotifyUserDataFailure: false,
        exportNotifyUserDataError: null,
        exportNotifyUserData: action.payload,
      };
    case MAIN_DASHBOARD.SEND_NOTIFIED_USER_MANUAL_MESSAGE_REQUEST:
      return {
        ...state,
        sendNotifiedUserManualMessageLoading: true,
        sendNotifiedUserManualMessageSuccess: false,
        sendNotifiedUserManualMessageFailure: false,
        sendNotifiedUserManualMessageError: null,
      };
    case MAIN_DASHBOARD.SEND_NOTIFIED_USER_MANUAL_MESSAGE_FAILURE:
      return {
        ...state,
        sendNotifiedUserManualMessageLoading: false,
        sendNotifiedUserManualMessageSuccess: false,
        sendNotifiedUserManualMessageFailure: true,
        sendNotifiedUserManualMessageError: action.payload,
      };
    case MAIN_DASHBOARD.SEND_NOTIFIED_USER_MANUAL_MESSAGE_SUCCESS:
      return {
        ...state,
        sendNotifiedUserManualMessageLoading: false,
        sendNotifiedUserManualMessageSuccess: true,
        sendNotifiedUserManualMessageFailure: false,
        sendNotifiedUserManualMessageError: null,
        sendManualMessage: action.payload,
      };
    default:
      return state;
  }
};
